import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseWebService } from "./base-web.service";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends BaseWebService {

    getProfile(): Observable<any> {
        return this.getMethod('/authentication/profile/');
    }

    editProfile(data: any): Observable<any> {
        return this.putMethod('/authentication/profile/', data);
    }

    getEducationalDetails(): Observable<any> {
        return this.getMethod('master/doctor/education_details/edit/');
    }

    editEducationalDetails(data: any): Observable<any> {
        return this.putMethod('master/doctor/education_details/edit/', data);
    }

    addEducation(data: any): Observable<any> {
        return this.postMethod('master/doctor/education/', data);
    }

    deleteEducation(id: any): Observable<any> {
        console.log(id)
        return this.deleteMethod('master/doctor/education/' + id);
    }

    getProfessionalDetails(): Observable<any> {
        return this.getMethod('master/doctor/professional_details/edit/');
    }

    editProfessionalDetails(data: any): Observable<any> {
        return this.putMethod('master/doctor/professional_details/edit/', data);
    }

    getLanguages(): Observable<any> {
        return this.getMethod('master/languages/');
    }

    getSpecializations(): Observable<any> {
        return this.getMethod('master/specializations/');
    }

    getSubSpecializations(id: any): Observable<any> {
        return this.getMethod('master/subspecializations/?specialization=' + id);
    }
}