import { Component, OnInit } from "@angular/core";

@Component({
    selector: "loader-component",
    templateUrl: "./loader.component.html",
})
export class LoaderComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
