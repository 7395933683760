import { Component, Input, OnInit } from "@angular/core";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-header-stats",
  templateUrl: "./header-stats.component.html",
})
export class HeaderStatsComponent implements OnInit {

  dashboardContents: any;
  
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.getDashboardContents();
  }

  getDashboardContents() {
    this.dashboardService.getDashboardContents().subscribe(
      response => {
        this.dashboardContents = response.data;
      },
      error => {
        console.log(error);
      }
    );
  }
}
