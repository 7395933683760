import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-doctor-register",
  templateUrl: "./register.component.html",
})
export class DoctorRegisterComponent implements OnInit {
  currentStep = 0;
  alertMessage: string;
  showAlert: boolean;

  getUpdatedvalue($event) {
    if ($event > 3) this.router.navigate(["/doctor/dashboard"]);
    this.currentStep = $event;
  }

  constructor(private router: Router) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.currentStep = this.router.getCurrentNavigation().extras.state.pageId;
      this.alertMessage = "You need to fill the details before continuing";
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    }
  }

  ngOnInit(): void {}
}
