import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AssignDoctorService } from "src/app/services/assign-doctor.service";
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { FormGroup, FormControl } from '@angular/forms';
import { map, startWith } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
@Component({
  selector: "change-password",
  templateUrl: "./change-password.component.html",
})

export class ChangePasswordComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private authService: AuthService,
    public snackBar: MatSnackBar) { }

  passwordDetails: any = {};

  ngOnInit(): void {

  }

  successSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = 'success-snackbar';
    this.snackBar.open(message, "Close", config);
  }

  failureSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = 'failure-snackbar';
    this.snackBar.open(message, "Close", config);
  }


  changePassword() {
    let passwordPattern = new RegExp(/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])^.{6,}$/);
    if (this.passwordDetails.old_password && this.passwordDetails.new_password && this.passwordDetails.confirm_password) {
      if (passwordPattern.test(this.passwordDetails.new_password)) {
        if (this.passwordDetails.new_password === this.passwordDetails.confirm_password) {
          this.authService.changePassword(this.passwordDetails)
            .subscribe(response => {
              console.log(response)
              this.successSnackbar('Succesfully Changed Password')
            },
              error => {
                this.failureSnackbar('Failed to Change Password')
              });
          this.closeAssignPopup();
        } else {
          this.failureSnackbar('Passwords did not match')
        }
      } else {
        this.failureSnackbar('Wrong Format of Password')
      }
    }
  }

  closeAssignPopup() {
    this.dialogRef.close();
  }

}
