import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "doctor-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class DoctorSidebarComponent implements OnInit {

  collapseShow = "hidden";
  userRole: any = '';
  section: any = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) { }

  ngOnInit() {

    this.userRole = localStorage.getItem('role');
    if (this.userRole === "ADMIN") {
      this.route.paramMap.subscribe((params) => {
        if (params.get('section') === "in-review") {
          this.section = 'in-review';
        } else if (params.get('section') === "assign-doctors") {
          this.section = 'assign-doctors';
        } else if (params.get('section') === "schedule") {
          this.section = 'schedule';
        }
      })
    }
  }

  logout() {
    this.authService.logout().subscribe();
    this.router.navigate(['/auth/login']);
  }

  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }
}
