import { Pipe, PipeTransform } from '@angular/core';
import * as statusData from '../app/interfaces/models';

@Pipe({
    name: 'translate'
})
export class TranslationPipe implements PipeTransform {
    transform(value: number): number {
        return statusData.StatusData[value];
    }
}
