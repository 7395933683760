import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseWebService } from "./base-web.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseWebService {

  getCaseList(page: any, status: any, search: any): Observable<any> {
    let query = 'master/admin/case_listing';
    if (status) {
      query = query + '?statuses=';
      status.forEach(element => {
        query = query + element + ',';
      });
      if (query) {
        query = query.slice(0, query.length - 1);
      }
    }
    if (page) {
      query = query + '&page=' + page;
    }
    if (search) {
      query = query + '&search=' + search;
    }

    return this.getMethod(query);
  }

  getDoctorsCaseList(page: any, search: any): Observable<any> {
    let query = 'master/doctor/case_listing/';
    if (page && search) {
      query = query + '?page=' + page + '&search=' + search;
    } else if (search && !page) {
      query = query + '?search=' + search;
    } else if (!search && page) {
      query = query + '?page=' + page;
    }
    return this.getMethod(query);
  }

  getDashboardContents(): Observable<any> {
    return this.getMethod('/master/admin/dashboard/');
  }
}