import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "../../environments/environment"
import { Router } from '@angular/router';
import 'rxjs/Rx';

import "rxjs/add/operator/map";
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import { SessionStorageService } from "./session-storage.service";


@Injectable()
export class BaseWebService {

    private baseURL = environment.apiUrl;

    constructor(private http: HttpClient, private router: Router, private sessionStorage: SessionStorageService) { }

    getMethod = (url: string, addToken = true): Observable<Response> => {
        return Observable.fromPromise(this.readToken())
            .flatMap(tokenData => {
                let header: HttpHeaders = new HttpHeaders();
                if (addToken && tokenData && tokenData != {} && typeof (tokenData) == 'string') {
                    header = new HttpHeaders().set("Authorization", "Token " + tokenData);
                }
                return this.http
                    .get(this.baseURL + url, { headers: header })
                    .map((res: Response) => {
                        return res
                    })
                    .catch((e: any) => {
                        if (addToken && e.status == 401) {
                            this.router.navigate(['/logout']);
                        }
                        return Observable.throw(e)
                    })
            }
            )
            .map((res: Response) => res)
            .catch(e => Observable.throw(e));
    }

    postMethod = (Url: string, Data, addToken = true): Observable<Response> => {
        return Observable.fromPromise(this.readToken())
            .flatMap(tokenData => {
                let header: HttpHeaders = new HttpHeaders();
                if (addToken && tokenData && tokenData != {} && typeof (tokenData) == 'string') {
                    header = new HttpHeaders().set("Authorization", "Token " + tokenData);
                }
                return this.http
                    .post(this.baseURL + Url, Data, { headers: header })
                    .map((res: Response) => res).catch((e: any) => {
                        if (addToken && e.status == 401) {
                            this.router.navigate(['/logout']);
                        }
                        return Observable.throw(e)
                    })
            }
            )
            .map((res: Response) => res)
            .catch(e => Observable.throw(e));
    }

    putMethod = (Url: string, Data, addToken = true): Observable<Response> => {
        return Observable.fromPromise(this.readToken())
            .flatMap(tokenData => {
                let header: HttpHeaders = new HttpHeaders();
                if (addToken && tokenData && tokenData != {} && typeof (tokenData) == 'string') {
                    header = new HttpHeaders().set("Authorization", "Token " + tokenData);
                }
                return this.http
                    .put(this.baseURL + Url, Data, { headers: header })
                    .map((res: Response) => res).catch((e: any) => {
                        if (addToken && e.status == 401) {
                            this.router.navigate(['/logout']);
                        }
                        return Observable.throw(e)
                    })
            }
            )
            .map((res: Response) => res)
            .catch(e => Observable.throw(e));
    }

    patchMethod = (Url: string, Data, addToken = true): Observable<Response> => {
        return Observable.fromPromise(this.readToken())
            .flatMap(tokenData => {
                let header: HttpHeaders = new HttpHeaders();
                if (addToken && tokenData && tokenData != {} && typeof (tokenData) == 'string') {
                    header = new HttpHeaders().set("Authorization", "Token " + tokenData);
                }
                return this.http
                    .patch(this.baseURL + Url, Data, { headers: header })
                    .map((res: Response) => res).catch((e: any) => {
                        if (addToken && e.status == 401) {
                            this.router.navigate(['/logout']);
                        }
                        return Observable.throw(e)
                    })
            }
            )
            .map((res: Response) => res)
            .catch(e => Observable.throw(e));
    }

    deleteMethod = (url: string, addToken = true): Observable<Response> => {
        return Observable.fromPromise(this.readToken())
            .flatMap(tokenData => {
                let header: HttpHeaders = new HttpHeaders();
                if (addToken && tokenData && tokenData != {} && typeof (tokenData) == 'string') {
                    header = new HttpHeaders().set("Authorization", "Token " + tokenData);
                }
                return this.http
                    .delete(this.baseURL + url, { headers: header })
                    .map((res: Response) => res).catch((e: any) => {
                        if (addToken && e.status == 401) {
                            this.router.navigate(['/logout']);
                        }
                        return Observable.throw(e)
                    })
            }
            )
            .map((res: Response) => res)
            .catch(e => Observable.throw(e));
    }

    getMethodBlob(Url): Observable<any> {
        return this.http
            .get(this.baseURL + Url, { responseType: 'blob' })
    }


    getErrorMessage(error) {
        if (typeof error.detail === 'string' || error.detail instanceof String) {
            return error.detail
        }

    }
    private readToken = () => {
        return this.sessionStorage.readItem("token")
            .then(response => {
                if (response === null) response = {};
                return response;
            });
    }

}
