import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { SessionStorageService } from './session-storage.service';

import { CookieService } from "ngx-cookie-service";



@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor( private sessionStorage: SessionStorageService,
        private cookieService: CookieService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let tokenizedReq = req.clone({
            withCredentials: true
        })
        return next.handle(tokenizedReq)
    }
}