
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
    selector: "app-drop-down-selector",
    templateUrl: 'dropdownselector.component.html'
})
export class DropDownSelectorComponent implements OnInit {
    @Output() selectedLanguagesChange = new EventEmitter<any>();
    @Input() prefillList: any;
    itemList = [];
    selectedItems = [];
    settings = {};
    constructor(private authService: AuthService,) { }
    ngOnInit() {
        this.authService.getLanguage()
            .subscribe(val => this.updateLanguageList(val),
                err => console.log(err))

        this.selectedItems = [];
        this.settings = {
            text: "",
            enableCheckAll: false,
            showCheckBox: true,
            enableSearchFilter: true,
            maxHeight: 150,
            tagToBody: false,
            autoPosition: false,
            classes: "myclass custom-class"
        };
    }
    updateLanguageList(value) {
        this.itemList = value.data
        for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i]['itemName'] = this.itemList[i]['name']
        }
        this.prefillList?.forEach(element => {
            this.itemList.forEach(lang => {
                if (lang.id === element) {
                    this.selectedItems.push(lang);
                }
            });
        });
    }
    onItemSelect(item: any) {
        this.selectedLanguagesChange.emit(this.selectedItems)
    }
    OnItemDeSelect(item: any) {
        this.selectedLanguagesChange.emit(this.selectedItems)
    }
    onSelectAll(items: any) {
        this.selectedLanguagesChange.emit(this.selectedItems)
    }
    onDeSelectAll(items: any) {
        this.selectedLanguagesChange.emit(this.selectedItems)
    }



}
