import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-admin-navbar",
  templateUrl: "./admin-navbar.component.html",
})
export class AdminNavbarComponent implements OnInit {

  userRole: any = '';
  profileImage: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
    this.profileImage = localStorage.getItem('profileImage');
  }

  goToDashboard() {
    if (this.userRole === 'DOCTOR') {
      this.router.navigate(['doctor/dashboard']);
    } else if (this.userRole === 'ADMIN') {
      this.router.navigate(['admin/dashboard/' + 'in-review']);
    }
  }
}
