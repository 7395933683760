import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AssignDoctorService } from "src/app/services/assign-doctor.service";
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: "assign-doctors",
  templateUrl: "./assign-doctor.component.html",
})
export class AssignDoctorsComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AssignDoctorsComponent>,
    private assignDoctorService: AssignDoctorService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data) {
      this.caseDetails = data.caseDetails;
      this.popupType = data.invokedFrom;
      this.doctorList = data.doctorList
    }

  assignDoctorForm = new FormGroup({
    firstDoctor: new FormControl(''),
    firstDoctorDate: new FormControl(''),
    firstDoctorHours: new FormControl(''),
    firstDoctorMinutes: new FormControl(''),
    firstDoctorEndHours: new FormControl(''),
    firstDoctorEndMinutes: new FormControl(''),
    secondDoctor: new FormControl(''),
    secondDoctorDate: new FormControl(''),
    secondDoctorHours: new FormControl(''),
    secondDoctorMinutes: new FormControl(''),
    secondDoctorEndHours: new FormControl(''),
    secondDoctorEndMinutes: new FormControl(''),
  });
  loaderStatus = false;
  doctorList: any = [];
  filteredDocList: any = [];
  secondDoctorList: any = [];
  firstDoctorList: any = [];
  selectedDoctor: any = {};
  prepopulateData: any = [];
  caseDetails: any;
  popupType: any;
  meetingLink: any = '';
  firstDoctor: any = {
    'doctor_profile': '',
    'date': new Date(),
    'start_time': '',
    'meridian': 'AM',
    'end_time': '',
    'endMeridian': 'AM',
  }
  secondDoctor: any = {
    'doctor_profile': '',
    'date': new Date(),
    'start_time': '',
    'meridian': 'AM',
    'end_time': '',
    'endMeridian': 'AM',
  }

  ngOnInit(): void {
    this.getDoctorList();
    // console.log(this.caseDetails, this.popupType);
    this.loaderStatus = true;
    this.assignDoctorForm.controls['firstDoctor'].valueChanges.subscribe(newValue=>{
      if ((typeof newValue) === 'number') {
        newValue = this.doctorList?.find(doc => doc.user_id === newValue)?.full_name;
      }
      this.firstDoctorList = this.filterValues(newValue);
      this.loaderStatus = false;
    })
    this.loaderStatus = true;
    this.assignDoctorForm.controls['secondDoctor'].valueChanges.subscribe(newValue => {
      if ((typeof newValue) === 'number') {
        newValue = this.doctorList?.find(doc => doc.user_id === newValue)?.full_name;
      }
      this.secondDoctorList = this.filterValues(newValue);
      this.loaderStatus = false;
    })
    if (this.caseDetails?.consultation_start_time && this.popupType === 'meeting') {
      this.setTimeAndDate(this.caseDetails?.consultation_start_time, this.caseDetails?.consultation_end_time, this.selectedDoctor);
    }
    if (this.popupType === 'edit-assign') {
      this.prepopulateOnEdit();
    }
  }

  prepopulateOnEdit() {
    this.loaderStatus = true;
    this.assignDoctorService.getAssignDoctors(this.caseDetails.id)
    .subscribe( response => {
      this.prepopulateData = response.data;
      this.setEditpopupData();
      this.loaderStatus = false;
    },
    error => {
      console.log(error);
      this.loaderStatus = false;
    });
  }

  setEditpopupData() {
    var dateObj1 = moment.utc(this.prepopulateData.doctors_assigned[0]?.start_time)
    var meridiem1 = dateObj1.hours() >= 12 ? "PM" : "AM";
    var convertedTime1 = moment(`${dateObj1.hours()}:${dateObj1.minutes()}:${meridiem1}`, 'HH:mm:ss').format('hh:mm A')

    var endDateObj1 = moment.utc(this.prepopulateData.doctors_assigned[0]?.end_time)
    var endMeridiem1 = endDateObj1.hours() >= 12 ? "PM" : "AM";
    var endConvertedTime1 = moment(`${endDateObj1.hours()}:${endDateObj1.minutes()}:${endMeridiem1}`, 'HH:mm:ss').format('hh:mm A')

    var dateObj2 = moment.utc(this.prepopulateData.doctors_assigned[1]?.start_time)
    var meridiem2 = dateObj2.hours() >= 12 ? "PM" : "AM";
    var convertedTime2 = moment(`${dateObj2.hours()}:${dateObj2.minutes()}:${meridiem2}`, 'HH:mm:ss').format('hh:mm A')

    var endDateObj2 = moment.utc(this.prepopulateData.doctors_assigned[1]?.end_time)
    var endMeridiem2 = endDateObj2.hours() >= 12 ? "PM" : "AM";
    var endConvertedTime2 = moment(`${endDateObj2.hours()}:${endDateObj2.minutes()}:${endMeridiem2}`, 'HH:mm:ss').format('hh:mm A')

    this.assignDoctorForm.setValue({
      firstDoctor: this.prepopulateData.doctors_assigned[0]?.doctor_profile, 
      firstDoctorDate: dateObj1.toDate(), 
      firstDoctorHours: convertedTime1.slice(0, 2),
      firstDoctorMinutes: convertedTime1.slice(3, 5),
      firstDoctorEndHours: endConvertedTime1.slice(0, 2),
      firstDoctorEndMinutes: endConvertedTime1.slice(3, 5),
      secondDoctor: this.prepopulateData.doctors_assigned[1]?.doctor_profile | 0,
      secondDoctorDate: dateObj2.toDate(), 
      secondDoctorHours: convertedTime2.slice(0, 2),
      secondDoctorMinutes: convertedTime2.slice(3, 5),
      secondDoctorEndHours: endConvertedTime2.slice(0, 2),
      secondDoctorEndMinutes: endConvertedTime2.slice(3, 5), })
    
    this.firstDoctor.meridian = meridiem1;
    this.firstDoctor.endMeridian = endMeridiem1;
    this.secondDoctor.meridian = meridiem2;
    this.secondDoctor.endMeridian = endMeridiem2;
  }

  filterValues(search: string) {
    return this.doctorList.filter(value =>
      value.full_name.toLowerCase().indexOf(search?.toLowerCase()) === 0);
  }

  setTimeAndDate(date: any, endDate, array: any) {
    var dateObj = new Date(date)
    var meridiem = dateObj.getHours() >= 12 ? "PM" : "AM";
    var convertedTime = moment(`${dateObj.getHours()}:${dateObj.getMinutes()}:${meridiem}`, 'HH:mm:ss').format('hh:mm A')
    array['date'] = dateObj;
    array['hours'] = convertedTime.slice(0, 2);
    array['minutes'] = convertedTime.slice(3, 5);
    array['ampm'] = meridiem

    dateObj = new Date(endDate)
    meridiem = dateObj.getHours() >= 12 ? "PM" : "AM";
    convertedTime = moment(`${dateObj.getHours()}:${dateObj.getMinutes()}:${meridiem}`, 'HH:mm:ss').format('hh:mm A')
    array['endHours'] = convertedTime.slice(0, 2);
    array['endMinutes'] = convertedTime.slice(3, 5);
    array['end_ampm'] = meridiem
  }

  getDocName(docID: any) {
    return this.doctorList?.find(doc => doc.user_id === docID)?.full_name;
  }

  getDoctorList() {
    this.loaderStatus = true;
    this.assignDoctorService.getDoctorList()
    .subscribe( response => {
      this.doctorList = response.data;
      this.secondDoctorList = response.data;
      this.firstDoctorList = response.data;
      this.loaderStatus = false;
    },
    error => {
      console.log(error);
      this.loaderStatus = false;
    });
  }

  successSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = 'success-snackbar';
    this.snackBar.open(message, "Close", config);
  }

  failureSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = 'failure-snackbar';
    this.snackBar.open(message, "Close", config);
  }

  assignDoctors() {
    let data = {};
    data['consultation'] = this.caseDetails.id;
    this.firstDoctor.doctor_profile = this.assignDoctorForm.value.firstDoctor;
    this.secondDoctor.doctor_profile = this.assignDoctorForm.value.secondDoctor;
    this.firstDoctor['start_time'] = this.formatTime(this.assignDoctorForm.value.firstDoctorDate,
      this.assignDoctorForm.value.firstDoctorHours, this.assignDoctorForm.value.firstDoctorMinutes, this.firstDoctor['meridian']);
    this.firstDoctor['end_time'] = this.formatTime(this.assignDoctorForm.value.firstDoctorDate, 
      this.assignDoctorForm.value.firstDoctorEndHours, this.assignDoctorForm.value.firstDoctorEndMinutes, 
      this.firstDoctor['endMeridian']);
    
    this.secondDoctor['start_time'] = this.formatTime(this.assignDoctorForm.value.secondDoctorDate, 
      this.assignDoctorForm.value.secondDoctorHours, this.assignDoctorForm.value.secondDoctorMinutes, this.secondDoctor['meridian']);
    this.secondDoctor['end_time'] = this.formatTime(this.assignDoctorForm.value.secondDoctorDate, 
      this.assignDoctorForm.value.secondDoctorEndHours, this.assignDoctorForm.value.secondDoctorEndMinutes, 
      this.secondDoctor['endMeridian']);

    this.firstDoctor['doctor_profile_url'] =  '';
    this.secondDoctor['doctor_profile_url'] = '';
    this.loaderStatus = true;
    if (this.popupType === 'edit-assign') {
      this.firstDoctor['id'] =  this.prepopulateData?.doctors_assigned[0]?.id;
      this.secondDoctor['id'] = this.prepopulateData?.doctors_assigned[1]?.id;
      data['doctors_assigned'] = [this.firstDoctor, this.secondDoctor];
      this.assignDoctorService.editAssignDoctors(data, this.caseDetails.id)
      .subscribe( response => {
        this.successSnackbar('Succesfully Updated')
        this.loaderStatus = false;
        },
        error => {
          this.failureSnackbar('Failed to Update')
          this.loaderStatus = false;
        });
    } else {
      data['doctors_assigned'] = [this.firstDoctor, this.secondDoctor];
      this.assignDoctorService.assignDoctors(data)
      .subscribe( response => {
        this.successSnackbar('Succesfully Assigned Doctors')
        this.loaderStatus = false;
        },
        error => {
          this.failureSnackbar('Failed to Assign Doctors')
          this.loaderStatus = false;
        });
    }
    
    this.closeAssignPopup();
  }

  createMeeting() {
    let data = {};
    data['meeting_link'] = this.meetingLink;
    this.loaderStatus = true;
    this.assignDoctorService.createMeeting(this.caseDetails.id, data)
      .subscribe(response => {
        this.successSnackbar('Succesfully Created Meeting');
        this.loaderStatus = false;
      },
        error => {
          this.failureSnackbar('Failed to Create Meeting');
          this.loaderStatus = false;
        });
    this.closeAssignPopup();
  }

  formatTime(date: any, h: any, m: any, ampm: any) {
    var date_str = date.getFullYear().toString() + "-" + ((date.getMonth() + 1).toString().length == 2 ? (date.getMonth() + 1).toString() : "0" + (date.getMonth() + 1).toString()) + "-" + (date.getDate().toString().length == 2 ? date.getDate().toString() : "0" + date.getDate().toString());
    var convertedTime = moment.utc(`${h}:${m} ${ampm}`, 'hh:mm A').format('HH:mm:ss')
    return `${date_str}T${convertedTime}`;
  }

  limitNumberWithinRange(num, min, max, e) {
    if (num) {
      const parsed = parseInt(num)
      e.srcElement.value = Math.min(Math.max(parsed, min), max)
    }
  }

  selectfirstAmOrPm(value) {
    this.firstDoctor.meridian = value
  }

  selectMeridian(array: any, key: any, value: any) {
    array[key] = value
  }

  closeAssignPopup() {
    this.dialogRef.close();
  }
}
