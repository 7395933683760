import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


@Component({
    selector: "app-patient-navbar",
    templateUrl: "./patient-navbar.component.html",
})
export class PatientNavbarComponent implements OnInit {
    @Input() currentPage: string;

    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }

    ngOnInit(): void {
    }

    onClickMyProfile() {
        this.router.navigate(['/patient/dashboard'],
            {
                state: { page: 'profilePage' }
            })
    }

    onLogout() {
        this.authService.logout().subscribe();
        this.router.navigate(['/auth/login']);

    }
}
