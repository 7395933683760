import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-alert-box",
    templateUrl: "./alert.component.html",
})
export class AlertBoxComponent implements OnInit {
    @Input() message: string;
    constructor() { }

    ngOnInit(): void {
    }
}
