import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ProfileService } from "src/app/services/doctor-profile.service";
import { ChangePasswordComponent } from "../change-password/change-password.component";

import { COUNTRY_CODES } from 'src/app/data/country-codes'
import { map, startWith } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs-compat";
import { PaymentSuccessComponent } from "../payment/success/payment-success.component";


@Component({
    selector: "doctor-profile",
    templateUrl: "./doctor-profile.component.html",
})
export class DoctorProfileComponent implements OnInit {
    constructor(private profileService: ProfileService,
        public snackBar: MatSnackBar,
        private dialog: MatDialog) { }

    isEditClicked: boolean = false;
    gender: any = '';
    personalDetails: any = [];
    educationalDetails: any = [];
    professionalDetails: any = [];
    userRole: any = '';
    doctorLanguages: any = [];
    doctorspecializations: any = [];
    tab: any = '#first';
    languagesIdList: any = [];
    specializationIdList: any = [];
    mainSpecializations: any = [];
    subSpecializations: any = [];
    selectedSubSpecialityItems: any = []
    files: File;
    imageFile: File;
    phone_codes = COUNTRY_CODES.map((country) => country.dial_code);
    countries = COUNTRY_CODES.map((country) => country.name);
    imageSrc: string | ArrayBuffer;
    newCourseDetails: any = {
        'course_name': '',
        'institution': '',
        'end_year': '',
        'certificate_file_path': File,
    };
    filteredOptions: any = [];
    loaderStatus = false;
    multiSelectSettings = {};

    ngOnInit(): void {
        this.userRole = localStorage.getItem('role');
        this.getProfile();
        if (this.userRole === 'DOCTOR') {
            document.getElementById("default-tab")?.click();
            this.getProfile();
            this.getEducationalDetails();
            this.getProfessionalDetails();
        }

        this.multiSelectSettings = {
            text: "",
            enableCheckAll: false,
            showCheckBox: true,
            enableSearchFilter: true,
            maxHeight: 150,
            tagToBody: false,
            autoPosition: false,
            classes: "myclass custom-class"
        };
    }

    doFilter(event: any) {
        this.filteredOptions = this.filter(event?.srcElement?.value);
    }

    filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.countries.filter(option => option.toLowerCase().includes(filterValue));
    }

    readURL(event: Event): void {
        this.imageFile = (event.target as HTMLInputElement).files[0];
        if (this.imageFile) {
            const file = this.imageFile;
            const reader = new FileReader();
            reader.onload = e => this.imageSrc = reader.result;
            reader.readAsDataURL(file);
        }
    }

    changeSpecialisation(id: any) {
        this.specializationIdList = []
        this.selectedSubSpecialityItems = [];
        this.getSubSpecialisations(id)
    }

    editProfile() {
        if (this.isEditClicked) {
            this.isEditClicked = false;
        } else {
            this.isEditClicked = true;
        }
    }

    selectGender(value) {
        this.gender = value
    }

    onUploadFile(event: Event): void {
        this.files = (event.target as HTMLInputElement).files[0];
        if (this.files) {
            const file = this.files;

            (document.getElementById('displayCertificateName') as HTMLInputElement).value = file.name;
        }
    }

    fetchReport(reportId: any) {
        window.open(this.educationalDetails.educations[reportId]?.certificate_file_path, '_blank');
    }

    successSnackbar(message) {
        let config = new MatSnackBarConfig();
        config.duration = 2000;
        config.panelClass = 'success-snackbar';
        this.snackBar.open(message, "Close", config);
    }

    failureSnackbar(message) {
        let config = new MatSnackBarConfig();
        config.duration = 2000;
        config.panelClass = 'failure-snackbar';
        this.snackBar.open(message, "Close", config);
    }

    addNewCourse() {
        this.loaderStatus = true;
        this.newCourseDetails['certificate_file_path'] = this.files;
        let request = new FormData();
        request.append('course_name', this.newCourseDetails.course_name);
        request.append('institution', this.newCourseDetails.institution);
        request.append('end_year', this.newCourseDetails.end_year);
        request.append('certificate_file_path', this.files);

        this.profileService.addEducation(request).subscribe(
            response => {
                this.educationalDetails.educations.push(response.data)
                this.successSnackbar('Successfully Added New Course');
                this.newCourseDetails = {
                    'course_name': '',
                    'institution': '',
                    'end_year': '',
                    'certificate_file_path': File,
                };
                this.loaderStatus = false;
            },
            error => {
                this.failureSnackbar('Failed to Add New Course');
                this.loaderStatus = false;
            }
        );
    }

    deleteCourse(id: any, index: any) {
        this.profileService.deleteEducation(id).subscribe(
            response => {
                this.educationalDetails.educations.splice(index, 1);
                this.successSnackbar('Successfully Deleted Course');
            },
            error => {
                this.failureSnackbar('Failed to Delete Course');
            }
        );
    }

    getProfile() {
        this.loaderStatus = true;
        this.profileService.getProfile().subscribe(
            response => {
                this.personalDetails = response.data;
                if (this.personalDetails?.profile_image_path) {
                    this.imageSrc = this.personalDetails?.profile_image_path;
                } else {
                    this.imageSrc = "../../../assets/img/user-profile-avatar.png";
                }
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.loaderStatus = false;
            }
        );
    }

    editPersonalDetails() {
        let request = new FormData();
        request.append('first_name', this.personalDetails.first_name);
        request.append('last_name', this.personalDetails.last_name);
        request.append('phone_number', this.personalDetails.phone_number);
        request.append('email', this.personalDetails.email);
        request.append('location', this.personalDetails.location);
        request.append('country', this.personalDetails.country);
        if (this.imageFile) {
            request.append('profile_image_path', this.imageFile);
        }
        this.loaderStatus = true;
        this.profileService.editProfile(request).subscribe(
            response => {
                this.isEditClicked = false;
                this.successSnackbar('Successfully Updated Personal Details');
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.failureSnackbar('Failed to Update Personal Details');
                this.loaderStatus = false;
            }
        );
    }

    editEducationalDetails() {
        let data = {
            'medical_registration_number': this.educationalDetails.medical_registration_number
        }

        if (this.languagesIdList) {
            data['languages'] = this.languagesIdList;
        }
        this.updateSpecializationList(this.selectedSubSpecialityItems)
        if (this.specializationIdList) {
            data['specializations'] = this.specializationIdList;
        }
        this.loaderStatus = true;
        this.profileService.editEducationalDetails(data).subscribe(
            response => {
                this.getEducationalDetails();
                this.isEditClicked = false;
                this.successSnackbar('Successfully Updated Education Details');
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.failureSnackbar('Failed to Update Education Details');
                this.loaderStatus = false;
            }
        );
    }

    editProfessionalDetails() {
        this.loaderStatus = true;
        this.profileService.editProfessionalDetails(this.professionalDetails).subscribe(
            response => {
                this.getProfessionalDetails();
                this.isEditClicked = false;
                this.successSnackbar('Successfully Updated Professional Details');
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.failureSnackbar('Failed to Update Professional Details');
                this.loaderStatus = false;
            }
        );
    }

    openChangePassword() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        let dialogRef = this.dialog.open(ChangePasswordComponent, dialogConfig);
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        })
    }

    updateProfile() {
        if (this.tab === "#first") {
            this.editPersonalDetails();
        } else if (this.tab === "#second") {
            this.editEducationalDetails();
        } else if (this.tab === "#third") {
            this.editProfessionalDetails();
        }
    }

    updateLanguageList(val) {
        this.languagesIdList = [];
        for (let i = 0; i < val.length; i++)
            this.languagesIdList.push(val[i]['id'])
    }

    updateSpecializationList(val) {
        this.specializationIdList = []
        if (val.length !== 0) {
            for (let i = 0; i < val.length; i++)
                this.specializationIdList.push(val[i]['id'])
        } else {
            this.specializationIdList.push(this.educationalDetails?.main_specialization.id)
        }
    }

    getSpecialisations() {
        this.loaderStatus = true;
        this.profileService.getSpecializations().subscribe(
            response => {
                this.mainSpecializations = response.data;
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.loaderStatus = false;
            }
        );
    }

    getSubSpecialisations(id: any) {
        this.loaderStatus = true;
        this.profileService.getSubSpecializations(id).subscribe(
            response => {
                this.subSpecializations = response.data;
                for (let i = 0; i < this.subSpecializations.length; i++) {
                    this.subSpecializations[i]['itemName'] = this.subSpecializations[i]['name']
                }
                this.specializationIdList.forEach(element => {
                    this.subSpecializations.forEach(special => {
                        if (special.id === element) {
                            this.selectedSubSpecialityItems.push(special);
                        }
                    });
                });
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.loaderStatus = false;
            }
        );
    }

    getEducationalDetails() {
        this.loaderStatus = true;
        this.profileService.getEducationalDetails().subscribe(
            response => {
                this.educationalDetails = response.data;
                this.languagesIdList = [];
                this.specializationIdList = [];
                this.getSpecialisations();
                this.getSubSpecialisations(this.educationalDetails?.main_specialization.id);
                if (this.educationalDetails.languages) {
                    this.educationalDetails.languages.forEach(element => {
                        this.languagesIdList.push(element.id);
                    });
                }
                if (this.educationalDetails.specializations) {
                    this.educationalDetails.specializations.forEach(element => {
                        this.specializationIdList.push(element.id)
                    });
                }
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.loaderStatus = false;
            }
        );
    }

    getProfessionalDetails() {
        this.loaderStatus = true;
        this.profileService.getProfessionalDetails().subscribe(
            response => {
                this.professionalDetails = response.data;
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.loaderStatus = false;
            }
        );
    }

    switchTab(e: any) {
        const target = e.target as Element;
        let tabsContainer = document.querySelector("#tabs");
        let tabTogglers = tabsContainer.querySelectorAll("a");
        e.preventDefault();
        let tabName = target.getAttribute('href');
        this.tab = tabName;
        let tabContents = document.querySelector("#tab-contents");
        if (tabName) {
            for (let i = 0; i < tabContents.children.length; i++) {
                tabTogglers[i].parentElement.classList.remove("border-blue-600", "text-lightBlue-600", "border-b-4", "-mb-px", "opacity-100");
                tabTogglers[i].parentElement.classList.add("opacity-50");
                tabContents.children[i].classList.remove("hidden");
                if ("#" + tabContents.children[i].id === tabName) {
                    continue;
                }
                tabContents.children[i].classList.add("hidden");
            }
            target.parentElement.classList.add("border-blue-600", "text-lightBlue-600", "border-b-4", "-mb-px", "opacity-100");
            target.parentElement.classList.remove("opacity-50");
        }
    }

    cancelEdit() {
        this.isEditClicked = false;
        if (this.tab === "#first") {
            this.getProfile();
        } else if (this.tab === "#second") {
            this.getEducationalDetails();
        } else if (this.tab === "#third") {
            this.getProfessionalDetails();
        }
    }
}
