import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  LoginRequest,
  User,
  SingupRequest,
  SignupRequestPatient,
  OtpVerificationRequest,
  OtpResendRequest,
  AddEducationRequest,
  AddEducationDetailsRequest,
  ProfessionDetailsRequest,
} from "src/app/interfaces/models";
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = environment.apiUrl;

  constructor(private router: Router, private http: HttpClient) {}

  headers = new HttpHeaders({
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  options = { headers: this.headers, withCredentials: true };

  privacyPolicyUrl = "https://www.google.com";

  signup(singupRequest: any): Observable<void> {
    return this.http.post<any>(
      this.baseUrl + "authentication/signup/",
      singupRequest
    );
  }

  confirm(email: string, code: string): Observable<void> {
    return this.http.post<any>("api/v1/authentication/verify_otp/", {
      email,
      code,
    });
  }

  login(loginRequest: LoginRequest): Observable<User> {
    return this.http.post<any>(
      this.baseUrl + "authentication/login/",
      loginRequest,
      this.options
    );
  }

  logout() {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    localStorage.removeItem("profileImage");
    localStorage.removeItem("defaultUrl");
    localStorage.removeItem("token");
    return this.http.get<any>(this.baseUrl + "authentication/logout/", {
      withCredentials: true,
    });
  }

  verifyOTP(otpVerificationRequest: OtpVerificationRequest): Observable<void> {
    return this.http.post<any>(
      this.baseUrl + "authentication/verify_otp/",
      otpVerificationRequest,
      this.options
    );
  }

  resendOTP(otpResendRequest: OtpResendRequest): Observable<void> {
    return this.http.post<any>(
      this.baseUrl + "authentication/resend_otp/",
      otpResendRequest
    );
  }

  getLanguage() {
    return this.http.get(this.baseUrl + "master/languages/", {
      withCredentials: true,
    });
  }

  getSubSpecializations(id: any) {
    return this.http.get(
      this.baseUrl + "master/subspecializations/?specialization=" + id,
      { withCredentials: true }
    );
  }

  getSpecializations() {
    return this.http.get(this.baseUrl + "master/specializations/", {
      withCredentials: true,
    });
  }

  addEducation(addEducationRequest: any): Observable<void> {
    return this.http.post<any>(
      this.baseUrl + "master/doctor/education/",
      addEducationRequest,
      { withCredentials: true }
    );
  }

  addEducationDetails(
    addEducationRequest: AddEducationDetailsRequest
  ): Observable<void> {
    return this.http.post<any>(
      this.baseUrl + "master/doctor/education_details/",
      addEducationRequest,
      { withCredentials: true }
    );
  }

  addProfessionalDetails(
    ProfessionalRequest: ProfessionDetailsRequest
  ): Observable<void> {
    return this.http.post<any>(
      this.baseUrl + "master/doctor/professional_details/",
      ProfessionalRequest,
      { withCredentials: true }
    );
  }

  addPatientReports(patientReport: any): Observable<void> {
    return this.http.post<any>(
      this.baseUrl + "master/patient/reports/",
      patientReport,

      { withCredentials: true }
    );
  }

  getPatientReports(consultationId): Observable<void> {
    return this.http.get<any>(
      this.baseUrl + `master/patient/consultation_reports/${consultationId}`,
      { withCredentials: true }
    );
  }

  getPatientAppointments(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "master/patient/appointments/", {
      withCredentials: true,
    });
  }

  requestConsultation(consultationRequest: any): Observable<void> {
    return this.http.put<any>(
      this.baseUrl + "master/patient/request_consultation/",
      consultationRequest,
      { withCredentials: true }
    );
  }

  getProfile(): Observable<void> {
    return this.http.get<any>(this.baseUrl + "authentication/profile/", {
      withCredentials: true,
    });
  }

  updatePatientProfile(request: any): Observable<void> {
    return this.http.patch<any>(
      this.baseUrl + "authentication/profile/",
      request,
      { withCredentials: true }
    );
  }

  chooseDoctor(consultationId: number): Observable<void> {
    return this.http.get<any>(
      this.baseUrl + `master/patient/doctor_selection/${consultationId}`,
      { withCredentials: true }
    );
  }

  makePayment(request: any, consultationId: number): Observable<void> {
    return this.http.put<any>(
      this.baseUrl + `master/patient/doctor_selection/${consultationId}`,
      request,
      { withCredentials: true }
    );
  }

  changePassword(request: any): Observable<void> {
    return this.http.put<any>(
      this.baseUrl + `authentication/change_password/`,
      request,
      { withCredentials: true }
    );
  }

  getDoctorSignUpStatus(): Observable<void> {
    return this.http.get<any>(this.baseUrl + "master/doctor/signup_status", {
      withCredentials: true,
    });
  }
}
