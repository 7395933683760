import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { PatientCheckoutService } from "src/app/services/patient-checkout.service";

import { environment } from "../../../environments/environment";
import { PaymentFailureComponent } from "../payment/failure/payment-failure.component";
import { PaymentSuccessComponent } from "../payment/success/payment-success.component";
declare const Stripe;
import * as moment from 'moment';

@Component({
  selector: "patient-dashboard-appointments",
  templateUrl: "./appointments.component.html",
})
export class PatientAppointmentsComponent implements OnInit {
  consultationTypeSelected: string;
  currentPage: string = "appointments";
  page: string;
  consultationStatus: string;
  consultationId: number;
  meetingLink: string;
  consultationType: string;
  consultationAmount: string = "0.0";
  paymentStatus: string;
  doctorsAssigned: [];
  selectedDoctorId: number;
  assignedDoctorName: string;
  assignedConsulationTime: any;
  assignedDoctorProfile: string;
  loaderStatus = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private patientCheckoutService: PatientCheckoutService
  ) {}

  ngOnInit() {
    this.loaderStatus = true;
    this.authService.getPatientAppointments().subscribe(
      (val) => {
        this.updateAppointmentStatus(val.data);
        this.loaderStatus = false;
      },
      (err) => {
        console.log(err);
        this.loaderStatus = false;
      }
    );

    this.consultationTypeSelected = "Call";

    // Check the payment status here
    this.route.queryParams.subscribe((params) => {
      if (params["status"] === "success") {
        this.openSuccessPopup();
      } else if (params["status"] === "cancel") {
        this.openFailurePopup();
      }
    });
  }

  updateAppointmentStatus(appointments) {
    if (appointments.length === 0) {
      this.router.navigate(["/patient/dashboard"]);
    }
    let last_appointment = [...appointments].pop();
    this.consultationStatus = last_appointment.status;
    this.consultationId = last_appointment.id;
    this.meetingLink = last_appointment.meeting_link;
    this.consultationType = last_appointment.consultation_type;
    this.consultationAmount = last_appointment.amount
      ? last_appointment.amount
      : "00.00";
    this.paymentStatus = last_appointment.payment_status;
    this.doctorsAssigned = last_appointment.doctors_assigned;
    // 'CONSULTATION_REQUESTED',
    // 'CONSULTATION_REJECTED',
    // 'CONSULTATION_APPROVED',
    // 'DOCTOR_ASSIGNED'
    // 'DOCTOR_SELECTED',
    // 'CONSULTATION_SCHEDULED
    // ‘COMPLETED’

    if (this.consultationStatus === "CONSULTATION_REQUESTED") {
      this.page = "pendingVerification";
    } else if (this.consultationStatus === "CONSULTATION_APPROVED") {
      this.page = "pendingVerification";
    } else if (this.consultationStatus === "REPORT_UPLOAD_PENDING") {
      this.router.navigate(["/patient/dashboard"], {
        state: { consultationId: this.consultationId },
      });
    } else if (this.consultationStatus === "DOCTOR_ASSIGNED") {
      this.onDoctorAssigned(last_appointment.id);
    } else if (this.consultationStatus === "DOCTOR_SELECTED") {
      this.onConsultationBooked();
    } else if (this.consultationStatus === "CONSULTATION_SCHEDULED") {
      this.onConsulationScheduled();
    } else {
      this.page = "noAppointments";
    }
  }

  openSuccessPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = this.dialog.open(PaymentSuccessComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
  }

  openFailurePopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = this.dialog.open(PaymentFailureComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
  }

  onConsulationScheduled() {
    this.loaderStatus = true;
    this.authService.chooseDoctor(this.consultationId).subscribe(
      (val) => {
        this.assignedDoctorName = val["data"].selected_doctor_name;
        this.assignedConsulationTime = val["data"].consultation_start_time;
        this.assignedDoctorProfile = val["data"].selected_doctor_profile_url;
        this.loaderStatus = false;
      },
      (err) => {
        console.log(err);
        this.loaderStatus = false;
      }
    );
    this.page = "callScheduled";
  }

  onConsultationBooked() {
    this.loaderStatus = true;
    this.authService.chooseDoctor(this.consultationId).subscribe(
      (val) => {
        this.updateAssignedDoctor(val);
        this.loaderStatus = false;
      },
      (err) => {
        console.log(err);
        this.loaderStatus = false;
      }
    );
    this.page = "consultationBooked";
  }

  updateAssignedDoctor(val) {
    this.assignedDoctorName = val.data.selected_doctor_name;
    this.assignedConsulationTime = val.data.consultation_start_time;
    this.assignedDoctorProfile = val.data.selected_doctor_profile_url;
  }

  onPaymentClick() {
    let data = {
      consultation_type: this.consultationTypeSelected,
      selected_doctor_id: this.selectedDoctorId,
    };
    console.log(data);
    this.loaderStatus = true;
    this.patientCheckoutService
      .assignDoctor(data, this.consultationId)
      .subscribe(
        (response) => {
          console.log(response);
          let sessionId = response.data.checkout_session_id;
          this.redirectToCheckout(sessionId);
          this.loaderStatus = false;
        },
        (error) => {
          console.log(error);
          this.loaderStatus = false;
        }
      );
  }

  redirectToCheckout(paymentSessionId: any) {
    const stripe = Stripe(environment.stripe_key);
    stripe.redirectToCheckout({
      sessionId: paymentSessionId,
    });
  }

  onDoctorAssigned(id) {
    this.loaderStatus = true;
    this.authService.chooseDoctor(id).subscribe(
      (val) => {
        this.updateDoctorList(val);
        this.loaderStatus = false;
      },
      (err) => {
        console.log(err);
        this.loaderStatus = false;
      }
    );
    this.page = "chooseDoctor";
  }

  getFormattedDateAndTime(date_string) {
    var date = new Date(Date.parse(date_string));
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    var time_str = this.formatAMPM(date);
    var date_str = day + "/" + month + "/" + year;

    return [date_str, time_str];
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm.toUpperCase();
    return strTime;
  }

  updateDoctorList(val) {
    this.doctorsAssigned = val.data.assigned_doctors;
    this.consultationId = val.data.id;
  }

  onSelectDoctor(id) {
    this.selectedDoctorId = id;
    let selectedDoc = this.doctorsAssigned.filter(
      (doctor) => doctor["id"] === id
    );
    this.consultationAmount = selectedDoc[0]["amount"];
  }
  selectConsulationType(type) {
    this.consultationTypeSelected = type;
  }
}
