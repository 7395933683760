import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AddEducationRequest, AddEducationDetailsRequest } from "src/app/interfaces/models";

@Component({
    selector: "app-doctor-education",
    templateUrl: "./education.component.html",
    styleUrls: ['./education.component.css']
})
export class DoctorEducationComponent implements OnInit {
    @Input() currentStep: number;
    @Output() currentStepChange = new EventEmitter<number>();
    education_form_submitted = false
    files: File;
    courses = [];
    languagesIdList = [];
    specializationIdList: any = [];
    mainSpecializations: any = [];
    selectedMainSpecializations: any;
    subSpecializations: any = [];
    selectedSubSpecialityItems: any = []
    loaderStatus = false;
    alertMessage = '';
    showAlert = false;
    tempData: any = [];
    multiSelectSettings = {};

    constructor(private authService: AuthService,) {
    }

    educationForm = new FormGroup({
        course_name: new FormControl('', Validators.required),
        year: new FormControl(null, Validators.required),
        college: new FormControl('', Validators.required),
        certificate: new FormControl(null, Validators.required),
    })

    otherDetailsForm = new FormGroup({
        medical_registration_number: new FormControl('', Validators.required),
    })
    get f() { return this.educationForm.controls; }

    onUploadFile(event: Event): void {
        this.files = (event.target as HTMLInputElement).files[0];
        if (this.files) {
            const file = this.files;

            (document.getElementById('displayCertificateName') as HTMLInputElement).value = file.name;
        }
    }

    updateLanguageList(val) {
        this.languagesIdList = [];
        for (let i = 0; i < val.length; i++)
            this.languagesIdList.push(val[i]['id'])
    }

    updateSpecializationList(val) {
        this.specializationIdList = []
        if (val.length !== 0) {
            for (let i = 0; i < val.length; i++)
                this.specializationIdList.push(val[i]['id'])
        } else {
            this.specializationIdList.push(this.selectedMainSpecializations)
        }
    }

    addNewCourse() {
        if (this.educationForm.invalid) {
            return;
        }
        else {
            this.loaderStatus = true;
            const temp = {
                course_name: this.f.course_name.value,
                year: this.f.year.value,
                college: this.f.college.value,
                certificate: this.f.certificate.value.split('\\')[2]
            }

            let request = new FormData();
            request.append('course_name', this.f.course_name.value);
            request.append('institution', this.f.college.value);
            request.append('end_year', this.f.year.value);
            request.append('certificate_file_path', this.files);

            this.authService.addEducation(request)
                .subscribe(
                    val => {
                        this.loaderStatus = false;
                        this.courses.push(temp);
                        this.files = null;
                        this.educationForm.reset();
                        (document.getElementById('displayCertificateName') as HTMLInputElement).value = '';
                    },
                    err => {
                        this.loaderStatus = false;
                        for (var key in err.error) {
                            this.alertMessage = err.error[key];
                        }
                        this.showAlert = true;
                        setTimeout(() => {
                            this.showAlert = false;
                        }, 5000);
                    });

        }

    }

    get g() { return this.otherDetailsForm.controls; }
    onSubmitEducationInfo() {
        this.education_form_submitted = true;
        if (this.otherDetailsForm.invalid) {
            return;
        }
        else {
            this.loaderStatus = true;
            this.updateSpecializationList(this.selectedSubSpecialityItems)
            const request: AddEducationDetailsRequest = {
                medical_registration_number: this.g.medical_registration_number.value,
                languages: this.languagesIdList,
                specializations: this.specializationIdList
            }
            this.authService.addEducationDetails(request)
                .subscribe(
                    val => this.routeToNext(val),
                    err => this.handleError(err))
        }

    }
    

    handleError(err) {
        this.loaderStatus = false;
        for (var key in err.error) {
            this.alertMessage = err.error[key];
        }
        this.showAlert = true;
        setTimeout(() => {
            this.showAlert = false;
        }, 5000);
    }

    routeToNext(val) {
        this.loaderStatus = false;
        this.currentStep += 1;
        this.currentStepChange.emit(this.currentStep)
    }

    getSpecialisations() {
        this.loaderStatus = true;
        this.authService.getSpecializations().subscribe(
            response => {
                this.tempData = response;
                this.mainSpecializations = this.tempData.data;
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.loaderStatus = false;
            }
        );
    }

    getSubSpecialisations(id: any) {
        this.loaderStatus = true;
        this.authService.getSubSpecializations(id).subscribe(
            response => {
                this.tempData = response;
                this.subSpecializations = this.tempData.data;
                for (let i = 0; i < this.subSpecializations.length; i++) {
                    this.subSpecializations[i]['itemName'] = this.subSpecializations[i]['name']
                }
                this.loaderStatus = false;
            },
            error => {
                console.log(error);
                this.loaderStatus = false;
            }
        );
    }

    changeSpecialisation(id: any) {
        this.specializationIdList = [];
        this.selectedSubSpecialityItems = [];
        this.getSubSpecialisations(id)
    }

    ngOnInit(): void {
        this.multiSelectSettings = {
            text: "",
            enableCheckAll: false,
            showCheckBox: true,
            enableSearchFilter: true,
            maxHeight: 150,
            tagToBody: false,
            autoPosition: false,
            classes: "myclass custom-class"
        };
        this.getSpecialisations();
    }


}
