import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginRequest, User } from "src/app/interfaces/models";
import { AuthService } from "src/app/services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { SessionStorageService } from "src/app/services/session-storage.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  loggedInUser: User;
  loginForm: FormGroup;
  message: string;
  returnUrl: string;
  isLoggedIn = false;
  alertStatus = false;
  submitted = false;
  loaderStatus: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      userid: ["", Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  showAlert(message) {
    this.message = message;
    this.alertStatus = true;
    setTimeout(() => {
      this.alertStatus = false;
    }, 5000);
  }

  async setSession(response: any) {
    await this.sessionStorage.saveItem("token", response.data.token);
    await this.sessionStorage.saveItem("user", response.data.user_id);
  }

  checkResponse(val) {
    this.loaderStatus = false;
    this.isLoggedIn = val.user_id != "";
    if (this.isLoggedIn) {
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("token", val.data.user_id);
      localStorage.setItem("role", val.data.role);
      localStorage.setItem("profileImage", val.data.profile_image_url);
      this.setSession(val);
      if (val.data.role === "DOCTOR") {
        this.router.navigate(["/doctor/dashboard"]);
        localStorage.setItem("defaultUrl", "/doctor/dashboard");
      } else if (val.data.role === "ADMIN") {
        this.router.navigate(["/admin/dashboard", "in-review"]);
        localStorage.setItem("defaultUrl", "/admin/dashboard/in-review");
      } else if (val.data.role == "PATIENT") {
        localStorage.setItem("defaultUrl", "/patient/appointments");
      }

      if (val.data.role == "PATIENT")
        this.router.navigate(["/patient/appointments"]);
      else if (val.data.role == "DOCTOR")
        this.router.navigate(["/doctor/dashboard"]);
    } else {
      console.log("Login failed");
    }
  }

  login() {
    if (this.loginForm.invalid) {
      this.submitted = true;
      return;
    }
    const loginRequest: LoginRequest = {
      username: this.f.userid.value,
      password: this.f.password.value,
    };
    this.loaderStatus = true;
    this.authService.login(loginRequest).subscribe(
      (val) => this.checkResponse(val),
      (err) => {
        this.loaderStatus = false;
        this.loginForm.reset();
        this.showAlert("Invalid Credentials");
      }
    );
  }
}
