import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseWebService } from "./base-web.service";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CaseService extends BaseWebService {

    getCaseDetails(id: any): Observable<any> {
        return this.getMethod('/master/case_details/' + id );
    }

    approveOrRjectCase(data: any) {
        return this.putMethod('/master/admin/status_change/', data );
    }
}