import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "payment-success",
  templateUrl: "./payment-success.component.html",
})
export class PaymentSuccessComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PaymentSuccessComponent>
  ) {}

  ngOnInit(): void {}

  confirmPopup() {
    this.dialogRef.close();
    window.history.pushState("Patient", "Appointments", "/patient/appointments");
  }
  
}
