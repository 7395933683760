import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import {MatPaginator, PageEvent, } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AssignDoctorsComponent } from '../assign-doctor/assign-doctor.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashBoardComponent implements OnInit {

  id: string;
  patientList: any = [];
  userRole: any = '';
  product_id: any;
  isDoctorDashboard: boolean = false;
  section: any = '';
  dashboardContents: any;
  page: number = 0;
  searchKey: any = '';
  status: any = [];
  pageEvent: PageEvent;
  dataSource = new MatTableDataSource();
  totalCount: any
  loaderStatus: boolean = false;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(
    private router: Router,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.id = localStorage.getItem('token');
    // this.getDashboardContents()
    this.loaderStatus = true;
    this.authService.getDoctorSignUpStatus()
      .subscribe(val => {
        this.checkDoctorSignUpStatus(val);
        this.loaderStatus = false;
      },
        err => {
          console.log(err);
          this.loaderStatus = false;
        })
    this.getCaseList(this.page, this.searchKey);
  } 

  checkDoctorSignUpStatus(val) {
    if (!val['data'].is_signup_completed) {
      if (!val['data'].has_education_details) {
        this.router.navigate(['/auth/register/doctor'], { state: { pageId: 2 } })
      }
      else if (!val['data'].has_professional_details) {
        this.router.navigate(['/auth/register/doctor'], { state: { pageId: 3 } })
      }
    }
  }

  getNext(event?:PageEvent) {
    this.getCaseList(event?.pageIndex + 1, this.searchKey);
    return event;
  }

  debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  searchAppointment(){
    this.getCaseList(this.page, this.searchKey);
  }

  searchInput = this.debounce(() => this.searchAppointment());

  getCaseList(page: any, search: any) {
    this.loaderStatus = true;
    this.dashboardService.getDoctorsCaseList(page, search).subscribe(
      response => {
        this.patientList = response.data.results;
        this.totalCount = response.data.count;
        this.loaderStatus = false;
      },
      error => {
        console.log(error);
        this.loaderStatus = false;
      }
    );
  }

  getDashboardContents() {
    this.loaderStatus = true;
    this.dashboardService.getDashboardContents().subscribe(
      response => {
        this.dashboardContents = response.data;
        this.loaderStatus = false;
      },
      error => {
        console.log(error);
        this.loaderStatus = false;
      }
    );
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = this.dialog.open(AssignDoctorsComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    })
  }

  viewPatient(id: any) {
    this.router.navigate(['/patient-detail', id]);
  }

  logout() {
    console.log('logout');
    this.authService.logout().subscribe();
    this.router.navigate(['/auth/login']);
  }

}