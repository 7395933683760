import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { SignupRequestPatient } from "src/app/interfaces/models";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { COUNTRY_CODES } from "src/app/data/country-codes";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-patient-personal-info",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.css"],
})
export class PatientPersonalInfoComponent implements OnInit {
  @Input() currentStep: number;
  @Output() currentStepChange = new EventEmitter<number>();

  phone_codes = COUNTRY_CODES.map((country) => country.dial_code);
  countries = COUNTRY_CODES.map((country) => country.name);
  personal_form_submitted = false;
  loaderStatus = false;
  imageSrc: string | ArrayBuffer;
  files: File;
  alertMessage: string = "";
  showAlert: boolean = false;
  privacyPolicyUrl: string;
  selectedOption;
  filteredOptions: Observable<string[]>;

  constructor(private authService: AuthService) {}

  profileForm = new FormGroup({
    profilePicture: new FormControl(null),
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    email: new FormControl("", Validators.email),
    password: new FormControl("", Validators.required),
    password_confirmation: new FormControl("", Validators.required),
    mobile_number: new FormGroup({
      code: new FormControl(null, Validators.required),
      number: new FormControl("", Validators.required),
    }),
    country: new FormControl(null, Validators.required),
    location: new FormControl("", Validators.required),
    privacyPolicy: new FormControl(false, Validators.requiredTrue),
  });

  get f() {
    return this.profileForm.controls;
  }

  onSubmitPersonalInfo() {
    this.personal_form_submitted = true;
    const singupRequest: SignupRequestPatient = {
      first_name: this.f.firstName.value,
      last_name: this.f.lastName.value,
      email: this.f.email.value,
      password: this.f.password.value,
      phone_number: this.f.mobile_number.value.number,
      country_code: this.f.mobile_number.value.code,
      location: this.f.location.value,
      country: this.f.country.value,
      role: "PATIENT",
    };

    if (this.profileForm.invalid) {
      return;
    } else {
      this.loaderStatus = true;
      this.authService.signup(singupRequest).subscribe(
        (val) => this.routeToVerification(val),
        (err) => this.handleError(err)
      );
    }
  }

  handleError(err) {
    this.loaderStatus = false;
    console.log(err.error);

    this.loaderStatus = false;
    for (var key in err.error) {
      this.alertMessage = err.error[key];
    }
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }

  routeToVerification(val) {
    this.loaderStatus = false;
    if (
      val["status"] === "success" &&
      val["message"] === "User Already Exists. Please login to continue!"
    ) {
      this.alertMessage = val["message"];
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    } else {
      this.currentStep += 1;
      localStorage.setItem("user_id", val.data.user_id);
      this.currentStepChange.emit(this.currentStep);
    }
  }

  ngOnInit(): void {
    this.privacyPolicyUrl = this.authService.privacyPolicyUrl;
    this.filteredOptions = this.f.country.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onCountrySelected(event, countryname) {
    if (event.isUserInput) {
      let selectedCountry = COUNTRY_CODES.filter(
        (country) => country.name === countryname
      );
      if (selectedCountry.length) {
        this.profileForm.controls.mobile_number["controls"]["code"].setValue(
          selectedCountry[0].dial_code
        );
      }
    }
  }
}
