import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
   providedIn: 'root'
})
export class AuthGuard implements CanActivate {
   constructor(private router: Router) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      let defaultUrl = localStorage.getItem('defaultUrl')
      if (!this.isLoggedIn()) {
         this.router.navigate(['/auth/login']);
         return false;
      } else if (route.url.toString() === '') {
         this.router.navigate([defaultUrl]);
      }
      return true;
   }

   public isLoggedIn(): boolean {
      let status = false;
      if (localStorage.getItem('isLoggedIn') == "true") {
         status = true;
      } else {
         status = false;
      }
      return status;
   }
}

