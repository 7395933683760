import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  OtpVerificationRequest,
  OtpResendRequest,
} from "src/app/interfaces/models";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-otp-form",
  templateUrl: "otp.component.html",
})
export class OTPComponent implements OnInit {
  @Input() currentStep: number;
  @Output() currentStepChange = new EventEmitter<number>();
  constructor(private authService: AuthService) {}

  message = "";
  showAlert = false;
  otpFormSubmitted = false;
  loaderStatus: boolean = false;

  ngOnInit(): void {}
  OTPForm = new FormGroup({
    first: new FormControl(null, Validators.required),
    second: new FormControl(null, Validators.required),
    third: new FormControl(null, Validators.required),
    fourth: new FormControl(null, Validators.required),
  });

  get f() {
    return this.OTPForm.controls;
  }

  focusNext(event, id) {
    var regex = /[0-9]|\./;
    if (id == "first" && this.f.first.value != "") {
      (document.getElementById("second") as HTMLInputElement).focus();
    }
    if (id == "second") {
      if (this.f.second.value != "") {
        (document.getElementById("third") as HTMLInputElement).focus();
      }
      if (event.key == "Backspace") {
        (document.getElementById("first") as HTMLInputElement).focus();
      }
    }
    if (id == "third") {
      if (this.f.third.value != "") {
        (document.getElementById("fourth") as HTMLInputElement).focus();
      }
      if (event.key == "Backspace") {
        (document.getElementById("second") as HTMLInputElement).focus();
      }
    }
    if (id == "fourth") {
      if (event.key == "Backspace") {
        (document.getElementById("third") as HTMLInputElement).focus();
      }
    }
  }
  onSubmitOTP() {
    this.otpFormSubmitted = true;
    if (this.OTPForm.invalid) {
      return;
    } else {
      this.loaderStatus = true;
      const otpVerificationRequest: OtpVerificationRequest = {
        user_id: localStorage.getItem("user_id"),
        code: this.f.first.value.concat(
          this.f.second.value,
          this.f.third.value,
          this.f.fourth.value
        ),
      };
      this.authService.verifyOTP(otpVerificationRequest).subscribe(
        (val) => this.routeNextForm(val),
        (err) => this.handleError(err)
      );
    }
  }

  handleError(err) {
    this.loaderStatus = false;
    this.message = "OTP verification failed";
    (document.getElementById("first") as HTMLInputElement).focus();
    this.OTPForm.reset();
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }

  resendOTP() {
    this.loaderStatus = true;
    const otpResendRequest: OtpResendRequest = {
      user_id: localStorage.getItem("user_id"),
    };
    (document.getElementById("first") as HTMLInputElement).focus();
    this.authService.resendOTP(otpResendRequest).subscribe(
      (val) => {
        this.loaderStatus = false;
      },
      (err) => {
        console.log(err);
        this.loaderStatus = false;
      }
    );
    this.message = "Check you mail for new OTP.";
    this.showAlert = true;
    this.OTPForm.reset();
    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }
  routeNextForm(val) {
    // alert verification successful
    this.loaderStatus = false;
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("user_id", val.data.user_id);
    localStorage.setItem("role", val.data.role);
    this.currentStep += 1;
    this.currentStepChange.emit(this.currentStep);
  }
}
