import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CaseService } from "src/app/services/case.service";
import * as moment from "moment";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { RejectPopupComponent } from "../reject-popup/reject-popup.component";

@Component({
  selector: "patient-details",
  templateUrl: "./patient-detail.component.html",
})
export class PatientDetailsComponent implements OnInit {
  patientId: any = "";
  caseDetails: any = [];
  consultationDate: any;
  consultationTime: any;
  loaderStatus: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private httpClient: HttpClient,
    public snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loaderStatus = true;
    this.route.paramMap.subscribe((params) => {
      this.patientId = params.get("id");
      this.getCaseDetails();
      this.loaderStatus = false;
    });
  }

  successSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = "success-snackbar";
    this.snackBar.open(message, "Close", config);
  }

  failureSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = "failure-snackbar";
    this.snackBar.open(message, "Close", config);
  }

  approveCase() {
    let data = {
      consultation_id: this.caseDetails.id,
      status: "CONSULTATION_APPROVED",
    };
    this.loaderStatus = true;
    this.caseService.approveOrRjectCase(data).subscribe(
      (response) => {
        this.successSnackbar("Successfully Approved the Appointment");
        this.loaderStatus = false;
        this.getCaseDetails();
      },
      (error) => {
        console.log(error);
        this.loaderStatus = false;
        this.failureSnackbar("Failed to Approve the Appointment");
      }
    );
  }

  // declineCase() {
  //   let data = {
  //     consultation_id: this.caseDetails.id,
  //     status: "CONSULTATION_REJECTED",
  //   };
  //   this.loaderStatus = true;
  //   this.caseService.approveOrRjectCase(data).subscribe(
  //     (response) => {
  //       this.getCaseDetails();
  //       this.successSnackbar("Successfully Rejected the Appointment");
  //       this.loaderStatus = false;
  //     },
  //     (error) => {
  //       console.log(error);
  //       this.loaderStatus = false;
  //       this.failureSnackbar("Failed to Reject the Appointment");
  //     }
  //   );
  // }

  getCaseDetails() {
    this.loaderStatus = true;
    this.caseService.getCaseDetails(this.patientId).subscribe(
      (response) => {
        this.caseDetails = response.data;
        if (this.caseDetails?.consultation_start_time) {
          this.consultationDate = new Date(
            this.caseDetails?.consultation_start_time
          );
          var meridiem = this.consultationDate.getHours() >= 12 ? "PM" : "AM";
          this.consultationTime = moment(
            `${this.consultationDate.getHours()}:${this.consultationDate.getMinutes()}:${meridiem}`,
            "HH:mm:ss"
          ).format("hh:mm A");
        }
        this.loaderStatus = false;
      },
      (error) => {
        console.log(error);
        this.loaderStatus = false;
      }
    );
  }

  fetchReport(reportId: any) {
    window.open(this.caseDetails.reports[reportId]?.report_url, "_blank");
  }

  async download(reportId: any) {
    try {
      const res = await this.getDocument(reportId);
      this.downloadFile(res);
    } catch (e) {
      console.log("error");
    }
  }

  async getDocument(reportId: any): Promise<any> {
    const resp = await this.httpClient
      .get(this.caseDetails.reports[reportId]?.report_url, {
        responseType: "blob",
      })
      .toPromise();
    return resp;
  }

  downloadFile(file) {
    const url = window.URL.createObjectURL(file);
    window.open(url);
  }

  gotoMeeting() {
    if (this.caseDetails.meeting_link) {
      window.open(this.caseDetails.meeting_link, "_blank", "");
    }
  }

  showRejectPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      caseId: this.caseDetails.id,
    };
    let dialogRef = this.dialog.open(RejectPopupComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "success") {
        console.log("called success", result);
        this.getCaseDetails();
      }
    });
  }
}
