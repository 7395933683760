import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';


@Component({
    selector: 'app-patient-dashboard',
    templateUrl: './patient-dashboard.component.html',
})
export class PatientDashBoardComponent implements OnInit {
    consultationId: number = null;
    profileSelected = true;
    reportsSelected = false;
    currentPage = "dashboard";
    alertStatus: boolean = false;
    alertMessage: string;


    get color(): string {
        return this._color;
    }
    set color(color: string) {
        this._color = color !== "light" && color !== "dark" ? "light" : color;
    }
    private _color = "light";

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
        if (this.router.getCurrentNavigation().extras.state) {
            this.consultationId = this.router.getCurrentNavigation().extras.state.consultationId;
            if (this.consultationId) {
                this.selectReports();
                this.alertMessage = "You need to request consultation";
                this.alertStatus = true;
                setTimeout(() => {
                    this.alertStatus = false;
                }, 5000);
            }
            if (this.router.getCurrentNavigation().extras.state.page === 'profilePage') {
                this.selectProfile();
            }
        }

    }

    ngOnInit() {

    }


    selectReports() {
        this.profileSelected = false;
        this.reportsSelected = true;
    }

    selectProfile() {
        this.reportsSelected = false;
        this.profileSelected = true;
    }

    logout() {
        console.log('logout');
        this.authService.logout().subscribe();
        this.router.navigate(['/auth/login']);
    }

}