import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input } from "@angular/core";
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from "ngx-file-drop";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "patient-dashboard-reports",
  templateUrl: "./reports.component.html",
})
export class PatientDashBoardReportsComponent implements OnInit {
  @Input() consultationId: number;
  public files: NgxFileDropEntry[] = [];
  uploadedFiles = [];
  // consultationId: number = null;
  lastAddedFile;
  fileAdded: boolean = false;
  isReportSubmitted: boolean = false;
  loaderStatus: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    if (this.consultationId) {
      this.loaderStatus = true;
      this.authService.getPatientReports(this.consultationId).subscribe(
        (val) => {
          this.loadOlderUploads(val);
          this.loaderStatus = false;
        },
        (err) => {
          this.loaderStatus = false;
          console.log(err);
        }
      );
    } else {
      this.loaderStatus = true;
      this.authService.getPatientAppointments().subscribe(
        (val) => {
          this.loaderStatus = false;
          if (val["data"].length > 0) {
            this.consultationId = val["data"][0]["id"];
            this.loaderStatus = true;
            this.authService.getPatientReports(this.consultationId).subscribe(
              (val) => {
                this.loadOlderUploads(val);
                this.loaderStatus = false;
              },
              (err) => {
                this.loaderStatus = false;
                console.log(err);
              }
            );
          }
        },
        (err) => {
          console.log(err);
          this.loaderStatus = false;
        }
      );
    }
  }

  fetchReport(reportURL: any) {
    window.open(reportURL, "_blank");
  }

  async download(reportURL: any) {
    try {
      const res = await this.getDocument(reportURL);
      this.downloadFile(res);
    } catch (e) {
      console.log("error");
    }
  }

  async getDocument(reportURL: any): Promise<any> {
    const resp = await this.httpClient
      .get(reportURL, { responseType: "blob" })
      .toPromise();
    return resp;
  }

  downloadFile(file) {
    const url = window.URL.createObjectURL(file);
    window.open(url);
  }

  loadOlderUploads(val) {
    for (let i = 0; i < val.data.reports.length; i++) {
      this.uploadedFiles.push({
        name: val.data.reports[i].report_file_path.split("/").slice(-1)[0],
        download_link: val.data.reports[i].report_file_path,
      });
    }
  }

  patientReportForm = new FormGroup({
    reportType: new FormControl("", Validators.required),
    reportDetails: new FormControl("", Validators.required),
  });

  get f() {
    return this.patientReportForm.controls;
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.lastAddedFile = file;
          this.fileAdded = true;
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  upoloadReport() {
    let request = new FormData();
    if (this.consultationId)
      request.append("consultation_id", String(this.consultationId));
    request.append("report_type", this.f.reportType.value);
    request.append("report_details", this.f.reportDetails.value);
    request.append("report_file_path", this.lastAddedFile);
    this.loaderStatus = true;
    this.authService.addPatientReports(request).subscribe(
      (val) => {
        this.loaderStatus = false;
        this.onReportUpload(val);
      },
      (err) => {
        this.loaderStatus = false;
        console.log(err);
      }
    );
  }

  onReportUpload(val) {
    if (!this.consultationId) {
      this.consultationId = val.data.consultation_id;
    }
    this.uploadedFiles.push({
      name: val.data.report_file_path.split("/").slice(-1)[0],
      download_link: val.data.report_file_path,
    });
    this.patientReportForm.reset();
    this.isReportSubmitted = true;
    this.fileAdded = false;
  }

  onSubmit() {
    this.loaderStatus = true;
    this.authService
      .requestConsultation({ consultation_id: this.consultationId })
      .subscribe(
        (val) => {
          this.loaderStatus = false;
          this.router.navigate(["/patient/appointments"]);
        },
        (err) => {
          this.loaderStatus = false;
          console.log(err);
        }
      );
  }
}
