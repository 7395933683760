import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfessionDetailsRequest } from 'src/app/interfaces/models';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';


@Component({
    selector: "app-doctor-professional",
    templateUrl: "./professional.component.html",
})
export class DoctorProfessionalComponent implements OnInit {
    @Input() currentStep: number;
    @Output() currentStepChange = new EventEmitter<number>();
    profession_info_form_submitted = false
    loaderStatus = false;
    alertMessage: string = '';
    showAlert: boolean = false;


    constructor(private authService: AuthService,) {
    }
    get f() { return this.professionalForm.controls; }

    professionalForm = new FormGroup({
        professional_bio: new FormControl('', Validators.required),
        clinical_experience: new FormControl(null, Validators.required),
        patients_consulted: new FormControl(null, Validators.required),
        patients_per_week: new FormControl(null, Validators.required),
        // prefered_consultation_time: new FormControl(null),
        prefered_consultation_start_hour: new FormControl(10),
        prefered_consultation_start_minute: new FormControl(0),
        prefered_consultation_start_period: new FormControl('am'),
        prefered_consultation_end_hour: new FormControl(10),
        prefered_consultation_end_minute: new FormControl(0),
        prefered_consultation_end_period: new FormControl('am'),
        consultation_fees: new FormControl(null, Validators.required),
        linkedin: new FormControl(null, Validators.pattern(/(http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/)),
        awards_and_publications: new FormControl(null, Validators.required),
    })

    onSubmitProfessionalInfo() {
        this.profession_info_form_submitted = true;
        let start_d = this.formatTime(this.f.prefered_consultation_start_hour.value, 
            this.f.prefered_consultation_start_minute.value, this.f.prefered_consultation_start_period.value);
        
        let end_d = this.formatTime(this.f.prefered_consultation_end_hour.value, 
            this.f.prefered_consultation_end_minute.value, this.f.prefered_consultation_end_period.value)

        const request: ProfessionDetailsRequest = {
            professional_bio: this.f.professional_bio.value,
            career_started_year: this.f.clinical_experience.value,
            number_of_patients_consulted: this.f.patients_consulted.value,
            average_patients_per_week: this.f.patients_per_week.value,
            preferred_consultation_start_time: start_d,
            preferred_consultation_end_time: end_d,
            consultation_fees: this.f.consultation_fees.value,
            linkedin_profile: this.f.linkedin.value,
            awards_and_publications: this.f.awards_and_publications.value
        }
        if (this.professionalForm.invalid) {
            return;
        }
        else {
            this.loaderStatus = true;
            this.authService.addProfessionalDetails(request)
                .subscribe(val => this.routeNext(val),
                    err => this.handleError(err))
        }
    }

    formatTime(h: any, m: any, ampm: any) {
        let timeStr = '';
        if (ampm === 'pm') {
            h = parseInt(h) + 12;
        }
        timeStr = `${h}:${m}`;
        let time = moment.utc(timeStr, 'HH:mm')
        return moment.utc(time).format('HH:mm');
    }


    handleError(err) {
        this.loaderStatus = false;
        for (var key in err.error) {
            this.alertMessage = err.error[key];
        }
        this.showAlert = true;
        setTimeout(() => {
            this.showAlert = false;
        }, 5000);
    }

    routeNext(val) {
        this.loaderStatus = false;
        this.currentStep += 1;
        this.currentStepChange.emit(this.currentStep)
    }

    ngOnInit(): void { }
}
