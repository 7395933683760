import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AssignDoctorService } from "src/app/services/assign-doctor.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { CaseService } from "src/app/services/case.service";
@Component({
  selector: "reject-popup",
  templateUrl: "./reject-popup.component.html",
})
export class RejectPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<RejectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private caseService: CaseService,
    public snackBar: MatSnackBar
  ) {}

  rejectReason: string;
  loaderStatus: boolean = false;

  ngOnInit(): void {}

  successSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = "success-snackbar";
    this.snackBar.open(message, "Close", config);
  }

  failureSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = "failure-snackbar";
    this.snackBar.open(message, "Close", config);
  }

  declineCase() {
    let requestData = {
      consultation_id: this.data.caseId,
      status: "CONSULTATION_REJECTED",
      comment: this.rejectReason,
    };
    console.log(requestData);
    this.loaderStatus = true;
    this.caseService.approveOrRjectCase(requestData).subscribe(
      (response) => {
        this.successSnackbar("Successfully Rejected the Appointment");
        this.loaderStatus = false;
        this.dialogRef.close("success");
      },
      (error) => {
        console.log(error);
        this.loaderStatus = false;
        this.failureSnackbar("Failed to Reject the Appointment");
      }
    );
  }

  closeAssignPopup() {
    this.dialogRef.close();
  }
}
