import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-auth-navbar",
  templateUrl: "./auth-navbar.component.html",
})
export class AuthNavbarComponent implements OnInit {
  navbarOpen = false;

  selectedTab = "login";

  constructor(private router: Router) {
    // this.route = this.router.url;

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        console.log(event["url"]);
        if (event["url"]?.startsWith("/auth/login")) {
          this.selectedTab = "login";
        }
        if (event["url"]?.startsWith("/auth/register")) {
          console.log(event["url"]?.startsWith("/auth/register"));
          this.selectedTab = "register";
        }
      });
  }

  ngOnInit(): void {}

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }
}
