import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-stepper",
    templateUrl: "stepper.component.html",
})
export class StepperComponent implements OnInit {
    @Input() currentStep: number;

    constructor() { }
    ngOnInit(): void { }

}
