import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ChangePasswordComponent } from "../../change-password/change-password.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "patient-dashboard-profile",
  templateUrl: "./profile.component.html",
})
export class PatientDashBoardProfileComponent implements OnInit {
  name: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: string;
  country: string;
  patientDetails: any = [];
  isEditClicked: boolean = false;
  isChangePasswordClicked: boolean = false;
  loaderStatus: boolean = false;

  constructor(private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.loaderStatus = true;
    this.authService.getProfile().subscribe(
      (val) => {
        this.updatePatientProfile(val);
        this.loaderStatus = false;
      },
      (err) => {
        console.log(err);
        this.loaderStatus = false;
      }
    );
  }

  updatePatientProfile(val) {
    this.loaderStatus = false;
    this.patientDetails = val.data;
    this.name = `${val.data.first_name} ${val.data.last_name}`;
    this.email = val.data.email;
    this.phoneNumber = val.data.phone_number;
    this.lastName = val.data.last_name;
    this.country = val.data.country;
    this.location = val.data.location;
  }

  onSave() {
    this.loaderStatus = true;
    this.name = (
      document.getElementById("patient-name") as HTMLInputElement
    ).value;
    this.phoneNumber = (
      document.getElementById("patient-phone-number") as HTMLInputElement
    ).value;
    this.location = (
      document.getElementById("patient-location") as HTMLInputElement
    ).value;
    this.country = (
      document.getElementById("patient-country") as HTMLInputElement
    ).value;

    const request = {
      first_name: this.patientDetails.first_name,
      last_name: this.patientDetails.last_name,
      phone_number: this.phoneNumber,
      email: this.email,
      country: this.country,
      location: this.location,
    };


    this.authService.updatePatientProfile(request).subscribe(
      (val) => {
        this.isEditClicked = false;
        this.loaderStatus = false;
        this.successSnackbar('Succesfully Updated Profile');
      },
      (err) => {
        this.undoUpdate(err);
        this.loaderStatus = false;
        this.failureSnackbar('Failed to Update Profile');
      }
    );
  }

  successSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = 'success-snackbar';
    this.snackBar.open(message, "Close", config);
  }

  failureSnackbar(message) {
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = 'failure-snackbar';
    this.snackBar.open(message, "Close", config);
  }


  undoUpdate(err) {
    this.authService.getProfile().subscribe(
      (val) => this.updatePatientProfile(val),
      (err) => {
        this.loaderStatus = false;
        console.log(err);
      }
    );
  }

  updatePasswordPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = this.dialog.open(ChangePasswordComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    })
  }
}
