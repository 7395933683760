import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: "app-register-patient",
  templateUrl: "./register.component.html",
})
export class PatientRegisterComponent implements OnInit {
  constructor(private router: Router,) { }
  currentStep = 0;
  getUpdatedvalue($event) {
    if ($event > 1)
      this.router.navigate(['/patient/appointments']);
    this.currentStep = $event;
  }
  ngOnInit(): void { }
}

