import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { SingupRequest } from "src/app/interfaces/models";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { COUNTRY_CODES } from "src/app/data/country-codes";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-doctor-personal-info",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.css"],
})
export class PersonalInfoComponent implements OnInit {
  @Input() currentStep: number;
  @Output() currentStepChange = new EventEmitter<number>();

  phone_codes = COUNTRY_CODES.map((country) => country.dial_code);
  countries = COUNTRY_CODES.map((country) => country.name);
  personal_form_submitted = false;
  gender = "";
  loaderStatus = false;
  imageSrc: string | ArrayBuffer;
  files: File;
  alertMessage: string = "";
  showAlert: boolean = false;
  privacyPolicyUrl: string;
  selectedOption;
  filteredOptions: Observable<string[]>;

  constructor(private authService: AuthService) {}

  profileForm = new FormGroup({
    profilePicture: new FormControl(null),
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    email: new FormControl("", Validators.email),
    password: new FormControl("", Validators.required),
    password_confirmation: new FormControl("", Validators.required),
    date_of_birth: new FormControl(null, Validators.required),
    gender: new FormControl("", Validators.required),
    mobile_number: new FormGroup({
      code: new FormControl(null, Validators.required),
      number: new FormControl("", Validators.required),
    }),
    country: new FormControl(null, Validators.required),
    location: new FormControl("", Validators.required),
    privacyPolicy: new FormControl(false, Validators.requiredTrue),
  });

  get f() {
    return this.profileForm.controls;
  }

  readURL(event: Event): void {
    this.files = (event.target as HTMLInputElement).files[0];
    if (this.files) {
      const file = this.files;

      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }

  onSubmitPersonalInfo() {
    this.personal_form_submitted = true;

    let request = new FormData();
    request.append('first_name', this.f.firstName.value);
    request.append('last_name', this.f.lastName.value);
    request.append('phone_number', this.f.mobile_number.value.number);
    request.append('email', this.f.email.value);
    request.append('password', this.f.password.value);
    request.append('date_of_birth', this.f.date_of_birth.value);
    request.append('gender', this.f.gender.value);
    request.append('country_code', this.f.mobile_number.value.code);
    request.append('location', this.f.location.value);
    request.append('country', this.f.country.value);
    request.append('profile_image_path', this.files);
    request.append('role', 'DOCTOR');

    if (this.profileForm.invalid) {
      return;
    } else {
      this.loaderStatus = true;
      this.authService.signup(request).subscribe(
        (val) => this.routeToVerification(val),
        (err) => this.handleError(err)
      );
    }
  }

  selectGender(value) {
    this.gender = value;
  }

  handleError(err) {
    this.loaderStatus = false;
    console.log(err.error);

    this.loaderStatus = false;
    for (var key in err.error) {
      this.alertMessage = err.error[key];
    }
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }

  routeToVerification(val) {
    this.loaderStatus = false;
    if (val.message === "User Already Exists. Please login to continue!") {
      this.alertMessage = "User Already Exists. Please login to continue!";
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    } else {
      this.currentStep += 1;
      localStorage.setItem("user_id", val.data.user_id);
      this.currentStepChange.emit(this.currentStep);
    }
  }

  ngOnInit(): void {
    this.privacyPolicyUrl = this.authService.privacyPolicyUrl;
    this.filteredOptions = this.f.country.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onCountrySelected(event, countryname) {
    if (event.isUserInput) {
      let selectedCountry = COUNTRY_CODES.filter(
        (country) => country.name === countryname
      );
      if (selectedCountry.length) {
        this.profileForm.controls.mobile_number["controls"]["code"].setValue(
          selectedCountry[0].dial_code
        );
      }
    }
  }
}
