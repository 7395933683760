import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./guards/auth.guard";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxFileDropModule } from "ngx-file-drop";
// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
// import { RegisterComponent } from "./views/auth/register/register.component";
// import { DoctorRegisterComponent } from './views/auth/register/doctor/register.component';
import { DoctorRegisterComponent } from "./views/auth/register/register.component";
import { OTPComponent } from "./views/auth/register/doctor/otp.component";
import { StepperComponent } from "./views/auth/register/doctor/stepper.component";
import { PersonalInfoComponent } from "./views/auth/register/doctor/personal.component";
import { DoctorEducationComponent } from "./views/auth/register/doctor/education.component";
import { DoctorProfessionalComponent } from "./views/auth/register/doctor/professional.component";
import { DropDownSelectorComponent } from "./views/auth/register/doctor/dropdownselector.component";
import { SpecialitySelectorComponent } from "./views/auth/register/doctor/speciality.component";
import { DashBoardComponent } from "./views/dashboard/dashboard.component";
import { AlertBoxComponent } from "./views/alert.component";
// import { DoctorEducationDetails } from "./views/auth/education/education_details.component";
// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import { PatientRegisterComponent } from "./views/auth/register/patient/register.component";
import { PatientPersonalInfoComponent } from "./views/auth/register/patient/personal.component";
import { PatientOTPComponent } from "./views/auth/register/patient/otp.component";

import { DashboardService } from "./services/dashboard.service";
import { PatientDetailsComponent } from "./views/patient-detail/patient-detail.component";
import { DoctorProfileComponent } from "./views/doctor-profile/doctor-profile.component";
import { DoctorSidebarComponent } from "./components/doctor-sidebar/sidebar.component";
import { PatientSidebarComponent } from "./components/patient-sidebar/sidebar.component";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";

import { PatientDashBoardComponent } from "./views/dashboard/patient/patient-dashboard.component";
import { PatientNavbarComponent } from "./components/navbars/index-navbar/patient-navbar.component";
import { PatientDashBoardProfileComponent } from "./views/dashboard/patient/profile.component";
import { PatientDashBoardReportsComponent } from "./views/dashboard/patient/reports.component";
import { PatientAppointmentsComponent } from "./views/patient-appointments/appointments.component";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatInputModule } from '@angular/material/input';
// import { MatButtonModule } from '@angular/material/button';
// import { MatListModule } from '@angular/material/list';
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { AssignDoctorsComponent } from "./views/assign-doctor/assign-doctor.component";
import { SessionStorageService } from "./services/session-storage.service";
import { TokenInterceptorService } from "./services/interceptor";
import { TranslationPipe } from "./translate.pipe";
import { AdminDashBoardComponent } from "./views/admin-dashboard/admin-dashboard.component";
import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { UserRoleGuard } from "./guards/user-role.guard";
import { ComingSoonComponent } from "./views/coming-soon/coming-soon.component";
import { PatientCheckoutService } from "./services/patient-checkout.service";
import { PaymentSuccessComponent } from "./views/payment/success/payment-success.component";
import { PaymentFailureComponent } from "./views/payment/failure/payment-failure.component";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { LoaderComponent } from "./views/loader/loader.component";
import { RejectPopupComponent } from "./views/reject-popup/reject-popup.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    DoctorSidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardTableComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    AlertBoxComponent,
    DoctorRegisterComponent,
    OTPComponent,
    StepperComponent,
    PersonalInfoComponent,
    DoctorEducationComponent,
    DoctorProfessionalComponent,
    DropDownSelectorComponent,
    SpecialitySelectorComponent,
    DashBoardComponent,
    IndexComponent,
    LandingComponent,
    ProfileComponent,
    PatientRegisterComponent,
    PatientPersonalInfoComponent,
    PatientOTPComponent,
    PatientDetailsComponent,
    DoctorProfileComponent,
    AssignDoctorsComponent,
    TranslationPipe,
    AdminDashBoardComponent,
    PatientDashBoardComponent,
    PatientNavbarComponent,
    PatientDashBoardProfileComponent,
    PatientDashBoardReportsComponent,
    PatientAppointmentsComponent,
    PatientSidebarComponent,
    ComingSoonComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    ChangePasswordComponent,
    LoaderComponent,
    RejectPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMultiSelectModule,
    HttpClientModule,
    // BrowserAnimationsModule,
    // MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxFileDropModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    // MatInputModule,
    // MatButtonModule,
    // MatListModule
  ],
  entryComponents: [
    AssignDoctorsComponent,
    ChangePasswordComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
  ],
  providers: [
    AuthGuard,
    UserRoleGuard,
    DashboardService,
    PatientCheckoutService,
    SessionStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    DashboardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
