import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "payment-failure",
  templateUrl: "./payment-failure.component.html",
})
export class PaymentFailureComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PaymentFailureComponent>
  ) {}

  ngOnInit(): void {}

  confirmPopup() {
    this.dialogRef.close();
    window.history.pushState("Patient", "Appointments", "/patient/appointments");
  }
  
}
