export interface User {
  role: string;
  user_id: string;
}

export class LoginRequest {
  username: string;
  password: string;
}

export class SingupRequest {
  first_name: string;
  last_name: string;
  phone_number: number;
  country_code: string;
  email: string;
  password: string;
  location: string;
  date_of_birth: string;
  gender: string;
  country: string;
  role: string;
  profile_image: File;
}

export class SignupRequestPatient {
  first_name: string;
  last_name: string;
  phone_number: number;
  country_code: string;
  email: string;
  password: string;
  location: string;
  country: string;
  role: string;
}
export class OtpVerificationRequest {
  user_id: string;
  code: string;
}

export class OtpResendRequest {
  user_id: string;
}

export class AddEducationRequest {
  course_name: string;
  institution: string;
  end_year: number;
  certificate_file_path: File;
}

export class AddEducationDetailsRequest {
  medical_registration_number: string;
  languages;
  specializations;
}

export class ProfessionDetailsRequest {
    professional_bio: string;
    career_started_year: number;
    number_of_patients_consulted: number;
    average_patients_per_week: number;
    preferred_consultation_start_time: string;
    preferred_consultation_end_time: string;
    consultation_fees: string;
    linkedin_profile: string;
    awards_and_publications: string;
}

export const StatusData = {
  REPORT_UPLOAD_PENDING: "Report Upload Pending",
  CONSULTATION_REQUESTED: "Pending",
  CONSULTATION_REJECTED: "Rejected",
  CONSULTATION_APPROVED: "Approved",
  DOCTOR_ASSIGNED: "Assigned",
  DOCTOR_SELECTED: "Schedule Pending",
  CONSULTATION_SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};
