import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';

import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { AssignDoctorsComponent } from '../assign-doctor/assign-doctor.component';
import { MatPaginator, PageEvent, } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { AssignDoctorService } from 'src/app/services/assign-doctor.service';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
})
export class AdminDashBoardComponent implements OnInit {

  id: string;
  patientList: any = [];
  caseInReviewList: any = [];
  assignDoctorList: any = [];
  scheduleList: any = [];
  status: any = [];
  userRole: any = '';
  page: number = 0;
  totalCount: number
  product_id: any;
  isDoctorDashboard: boolean = false;
  section: any = '';
  dashboardContents: any;
  pageEvent: PageEvent;
  dataSource = new MatTableDataSource();
  searchKey: any = '';
  doctorList: any = [];
  loaderStatus = false;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(
    private router: Router,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private assignDoctorService: AssignDoctorService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.id = localStorage.getItem('token');
    this.dataSource.paginator = this.paginator;
    this.loaderStatus = true;
    this.route.paramMap.subscribe((params) => {
      if (params.get('section') === "in-review") {
        this.section = 'in-review';
        this.status = ['CONSULTATION_REQUESTED', 'CONSULTATION_REJECTED', 'CONSULTATION_APPROVED'];
      } else if (params.get('section') === "assign-doctors") {
        this.section = 'assign-doctors';
        this.status = ['DOCTOR_ASSIGNED', 'CONSULTATION_APPROVED'];
      } else if (params.get('section') === "schedule") {
        this.section = 'schedule';
        this.status = ['DOCTOR_SELECTED', 'CONSULTATION_SCHEDULED'];
      }
      this.getCaseList(this.page, this.status, this.searchKey);
      this.loaderStatus = false;
    })
    this.getDashboardContents()
    this.getDoctorList();
  } 

  getNext(event?:PageEvent) {
    this.getCaseList(event?.pageIndex + 1, this.status, this.searchKey);
    return event;
  }

  debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  searchAppointment(){
    this.getCaseList(this.paginator.pageIndex, this.status, this.searchKey);
  }

  searchInput = this.debounce(() => this.searchAppointment());

  getCaseList(page: any, status: any, search: any) {
    this.loaderStatus = true;
    this.dashboardService.getCaseList(page, status, search).subscribe(
      response => {
        this.patientList = response.data.results;
        this.dataSource = this.patientList;
        this.caseInReviewList = [];
        this.assignDoctorList = [];
        this.scheduleList = [];
        this.patientList.forEach(element => {
          if (element.status === 'CONSULTATION_REQUESTED' || element.status === 'CONSULTATION_REJECTED' || element.status === 'CONSULTATION_APPROVED') {
            this.caseInReviewList.push(element);
          }
          if (element.status === 'DOCTOR_ASSIGNED' || element.status === 'CONSULTATION_APPROVED') {
            this.assignDoctorList.push(element);
          }
          if (element.status === 'DOCTOR_SELECTED' || element.status === 'CONSULTATION_SCHEDULED') {
            this.scheduleList.push(element);
          }
        });
        this.totalCount = response.data.count;
        this.loaderStatus = false;
      },
      error => {
        console.log(error);
        this.loaderStatus = false;
      }
    );
  }

  getDashboardContents() {
    this.loaderStatus = true;
    this.dashboardService.getDashboardContents().subscribe(
      response => {
        this.dashboardContents = response.data;
        this.loaderStatus = false;
      },
      error => {
        console.log(error);
        this.loaderStatus = false;
      }
    );
  }

  getDoctorList() {
    this.assignDoctorService.getDoctorList()
    .subscribe( response => {
      this.doctorList = response.data;
    },
    error => {
      console.log(error);
    });
  }

  
  openDialog(id: any, invokedFrom: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.patientList.forEach(element => {
      if (element.id === id) {
        dialogConfig.data = {
          "caseDetails": element,
          "doctorList": this.doctorList,
          "invokedFrom": invokedFrom
        };
      }
    });
    let dialogRef = this.dialog.open(AssignDoctorsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.getCaseList(this.page, this.status, this.searchKey);
    });
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    })
  }

  viewPatient(id: any) {
    this.router.navigate(['/patient-detail', id]);
  }

}