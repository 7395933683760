import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseWebService } from "./base-web.service";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AssignDoctorService extends BaseWebService {

    getDoctorList(): Observable<any> {
        return this.getMethod('master/doctors/');
    }

    assignDoctors(data: any) {
        return this.postMethod('master/admin/doctor_assignment/', data );
    }

    getAssignDoctors(id: any): Observable<any> {
        return this.getMethod('master/admin/doctor_assignment/' + id);
    }

    editAssignDoctors(data: any, id: any) {
        return this.putMethod('master/admin/doctor_assignment/' + id, data );
    }

    createMeeting(id: any, data: any) {
        return this.putMethod('master/admin/schedule_meeting/' + id, data );
    }
}