
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: "app-speciality-selector",
    templateUrl: 'dropdownselector.component.html'
})
export class SpecialitySelectorComponent implements OnInit {
    @Output() selectedSpecializationChange = new EventEmitter<any>();
    @Input() prefillList: any
    itemList = [];
    selectedItems = [];
    settings = {};
    constructor(private authService: AuthService,) { }
    ngOnInit() {
        this.authService.getSpecializations()
            .subscribe(val => this.updateSpecilizationList(val),
                err => console.log(err))

        this.selectedItems = [];
        this.settings = {
            text: "",
            enableCheckAll: false,
            showCheckBox: true,
            enableSearchFilter: true,
            maxHeight: 150,
            tagToBody: false,
            autoPosition: false,
            classes: "myclass custom-class"
        };
    }
    updateSpecilizationList($event) {
        this.itemList = $event.data
        for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i]['itemName'] = this.itemList[i]['name']
        }
        this.prefillList.forEach(element => {
            this.itemList.forEach(lang => {
                if (lang.id === element) {
                    this.selectedItems.push(lang);
                }
            });
        });
    }
    onItemSelect(item: any) {
        this.selectedSpecializationChange.emit(this.selectedItems)
    }
    OnItemDeSelect(item: any) {
        this.selectedSpecializationChange.emit(this.selectedItems)
    }
    onSelectAll(items: any) {
        this.selectedSpecializationChange.emit(this.selectedItems)
    }
    onDeSelectAll(items: any) {
        this.selectedSpecializationChange.emit(this.selectedItems)
    }



}
