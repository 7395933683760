import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
// import { RegisterComponent } from "./views/auth/register/register.component";
// import { DoctorEducationDetails } from './views/auth/education/education_details.component';
// import { DoctorRegisterComponent } from './views/auth/register/doctor/register.component';
import { DoctorRegisterComponent } from './views/auth/register/register.component';
import { DropDownSelectorComponent } from './views/auth/register/doctor/dropdownselector.component';
import { DashBoardComponent } from './views/dashboard/dashboard.component';
import { PatientRegisterComponent } from './views/auth/register/patient/register.component';
// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { AuthGuard } from "./guards/auth.guard";
import { PatientDetailsComponent } from "./views/patient-detail/patient-detail.component";
import { DoctorProfileComponent } from "./views/doctor-profile/doctor-profile.component";
import { AdminDashBoardComponent } from "./views/admin-dashboard/admin-dashboard.component";
import { PatientDashBoardComponent } from './views/dashboard/patient/patient-dashboard.component';
import { PatientAppointmentsComponent } from './views/patient-appointments/appointments.component';
import { UserRoleGuard } from "./guards/user-role.guard";
import { ComingSoonComponent } from "./views/coming-soon/coming-soon.component";
import { PaymentSuccessComponent } from "./views/payment/success/payment-success.component";
import { PaymentFailureComponent } from "./views/payment/failure/payment-failure.component";
import { PatientDashBoardProfileComponent } from "./views/dashboard/patient/profile.component";
import { PatientDashBoardReportsComponent } from "./views/dashboard/patient/reports.component";


const routes: Routes = [
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register/patient", component: PatientRegisterComponent },
      { path: "register/doctor", component: DoctorRegisterComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
  { path: 'patient/dashboard', component: PatientDashBoardComponent, canActivate: [UserRoleGuard], data: { expectedRole: 'PATIENT' } },
  { path: 'patient/profile', component: PatientDashBoardProfileComponent, canActivate: [UserRoleGuard], data: { expectedRole: 'PATIENT' } },
  { path: "patient/appointments", component: PatientAppointmentsComponent, canActivate: [UserRoleGuard], data: { expectedRole: 'PATIENT' } },
  { path: "patient/reports", component: PatientDashBoardReportsComponent, canActivate: [UserRoleGuard], data: { expectedRole: 'PATIENT' } },
  { path: "doctor/dashboard", component: DashBoardComponent, canActivate: [UserRoleGuard], data: { expectedRole: 'DOCTOR' } },
  { path: "admin/dashboard/:section", component: AdminDashBoardComponent, canActivate: [UserRoleGuard], data: { expectedRole: 'ADMIN' } },
  { path: "profile", component: DoctorProfileComponent, canActivate: [AuthGuard] },
  { path: "coming-soon/:section", component: ComingSoonComponent, canActivate: [AuthGuard] },
  { path: "patient-detail/:id", component: PatientDetailsComponent, canActivate: [AuthGuard] },
  { path: "patient-detail", component: PatientDetailsComponent, canActivate: [AuthGuard] },
  { path: "", component: LoginComponent, canActivate: [AuthGuard], pathMatch: "full" }
  // { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
