import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import * as localForage from "localforage";

@Injectable()
export class SessionStorageService {

    constructor() { }

    /**
     * @param  {string} itemKey Key to be saved in localforage
     * @param  {any} data contains data to save in localforage
     */
    saveItem = async (itemKey: string, data: any) => {
        try {
            return await localForage.setItem(itemKey, data);
        }
        catch (error) {
            return error;
        }
    }

    /**
     * Function to remove item from localforage.
     * @param  {string} itemKey contains itemKey to remove an item from localforage
     */
    removeItem = async (itemKey: string) => {
        try {
            await localForage.removeItem(itemKey)
        }
        catch (error) {
            return error;
        }
    }

    /**
     * Function to clear items from localforage
     */
    clearItems = async () => {
        try {
            await localForage.clear();
        }
        catch (error) {
            return error;
        }
    }

    /**
     * Function to read item from localforage.
     * @param  {string} itemKey Contains itemkey to read items from localforage
     */
    readItem = async (itemKey: string) => {
        try {
            return await localForage.getItem(itemKey)
        }
        catch (error) {
            return null;
        }
    }
}
