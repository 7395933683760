import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseWebService } from "./base-web.service";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PatientCheckoutService extends BaseWebService {

    assignDoctor(data: any, id: any): Observable<any> {
        return this.putMethod('master/patient/doctor_selection/' + id, data);
    }
}